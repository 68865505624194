.image-viewer-container {
  max-width: 100vw !important;
  width: 100%;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  display: inherit;

  .mat-dialog-container {
    background-color: rgba(0, 0, 0, .2);
    padding: 0;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 320px) {
      width: calc(100% - 15px);
      height: calc(100vh - 100px);
    }

    @media (min-width: 600px) {
      width: calc(100% - 200px);
      height: calc(100vh - 60px);
    }

    button.button-close {
      right: 24px;
      position: absolute;

      mat-icon {
        color: white;
      }
    }

    .swiper-container {
      height: calc(100vh - 60px);
      max-height: calc(100vh - 60px);

      swiper{
        height: 100%;
      }

      .slider-item {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 60px);
        object-fit: cover;
        border-radius: 16px;
      }

      .swiper-buttons {
        top: 44% !important;
        background-color: rgba(255, 255, 255, .3) !important;
        width: 64px;
        height: 64px;

        mat-icon {
          font-size: 64px;
          width: 64px;
          height: 64px;

          &:hover {
            cursor: pointer;
          }
        }

        &.swiper-button-prev {
          left: 0 !important;
        }

        &.swiper-button-next {
          right: 0 !important;
        }
      }
    }
  }
}
