.swiper-container {
  position: relative;

  .swiper-buttons {
    position: absolute;
    height: 48px;
    width: 48px;
    max-width: 48px;
    border-radius: 50% !important;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
    top: 32%;
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    mat-icon {
      font-size: 48px;
      width: 44px;
      height: 44px;

      &:hover {
        cursor: pointer;
      }
    }

    &.swiper-button-prev {
      left: 0;
    }

    &.swiper-button-next {
      right: 0;
    }
  }
}
