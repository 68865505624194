.dialog-reservation {
  @media(min-width: 320px) {
    width: calc(100% - 30px);
  }

  @media(min-width: 992px) {
    width: calc(100% - 200px);
  }

  @media(min-width: 1200px) {
    width: calc(100% - 400px);
  }

  @media(min-width: 1600px) {
    width: calc(100% - 600px);
  }

  .mat-dialog-container {
    padding: 0 !important;
    max-width: 100% !important;
  }
}
